'use client';

import React, { useCallback, useState, useEffect, useMemo } from 'react';
import { InfoMessageView } from './info-message.view';

export type MessageType = 'alert' | 'default' | 'success' | 'dialog';

export type InfoMessageProps = {
  dismissible: boolean | null | undefined;
  id: string;
  link: null | {
    url: string;
    urlText: string;
  };
  marketSlug: string;
  message: string;
  messageType: MessageType;
  modelSlug?: string;
  title?: string | null;
  closeAriaLabel?: string;
  className?: string;
  classNameInner?: string;
};

const hasLocalStorage = typeof localStorage !== 'undefined';
const isBrowser = typeof window !== 'undefined';

export const InfoMessage = ({
  dismissible,
  id,
  link,
  marketSlug,
  message,
  messageType,
  modelSlug,
  title,
  closeAriaLabel,
  className,
  classNameInner,
}: InfoMessageProps) => {
  const [showInfoMessage, setShowInfoMessage] = useState(false);

  const INFO_MESSAGE_KEY = useMemo(() => {
    const model = modelSlug ? `-${modelSlug}` : '';
    const infoMessageKey = `${id}-InfoMessageHasBeenClosed-${marketSlug}${model}`;

    return isBrowser && infoMessageKey;
  }, [id, marketSlug, modelSlug]);

  const infoMessageHasBeenDismissed = useCallback(() => {
    return (
      hasLocalStorage &&
      INFO_MESSAGE_KEY &&
      localStorage.getItem(INFO_MESSAGE_KEY) === 'true'
    );
  }, [INFO_MESSAGE_KEY]);

  const dismissInfoMessage = useCallback(() => {
    setShowInfoMessage(false);

    return (
      hasLocalStorage &&
      INFO_MESSAGE_KEY &&
      localStorage.setItem(INFO_MESSAGE_KEY, 'true')
    );
  }, [INFO_MESSAGE_KEY]);

  useEffect(() => {
    const hasBeenDismissed = infoMessageHasBeenDismissed();

    if (hasBeenDismissed) {
      setShowInfoMessage(false);
    }

    if (!hasBeenDismissed) {
      setShowInfoMessage(true);
    }
  }, [infoMessageHasBeenDismissed]);

  if (!showInfoMessage || message === '') {
    return null;
  }

  return (
    <InfoMessageView
      dismissible={dismissible}
      dismissInfoMessage={dismissInfoMessage}
      link={link}
      message={message}
      messageType={messageType}
      title={title}
      closeAriaLabel={closeAriaLabel || 'close'}
      className={className}
      classNameInner={classNameInner}
    />
  );
};

export default InfoMessage;
