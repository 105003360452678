'use client';

import React, { useState, useEffect, MutableRefObject, useRef } from 'react';
import {
  ExtendCSS,
  Text,
  Spacer,
  TypeVariants,
  SpacerProps,
  Flex,
  PropsWithAs,
} from 'vcc-ui';
import { Markdown_DEPRECATED_VCCUI } from '@vcc-package/text/DEPRECATED';
import { PopUpDisclaimer, PopUpDisclaimerProps } from '@vcc-package/overlays';
import { Track } from '@volvo-cars/tracking';
export interface HeadingAndTextProps {
  ariaLabelReadMoreButton?: string;
  enableLineBreak?: boolean;
  extendHeadingCSS?: ExtendCSS;
  extendTextCss?: ExtendCSS;
  heading?: string;
  headingSource?: string;
  headingVariant?: TypeVariants;
  markdownVariant?: TypeVariants;
  readMoreTitle?: string;
  readMoreTrackingLabel?: string;
  spacerSize?: SpacerProps['size'];
  supportMarkdown?: boolean;
  text: string;
  textSource?: string;
  withHeadingType?: PropsWithAs['Type'];
  popUpDisclaimer?: PopUpDisclaimerProps;
  index?: number;
  background?: 'white' | 'light' | 'dark' | 'black';
}

const HeadingAndText: React.FC<
  React.PropsWithChildren<HeadingAndTextProps>
> = ({
  ariaLabelReadMoreButton = 'headingAndTextReadMore',
  enableLineBreak = false,
  extendTextCss,
  extendHeadingCSS,
  heading,
  headingSource,
  headingVariant = 'hillary',
  markdownVariant = 'columbus',
  readMoreTitle,
  readMoreTrackingLabel,
  spacerSize = 1,
  supportMarkdown = false,
  text,
  textSource,
  withHeadingType = 'h3',
  popUpDisclaimer,
  index,
  background = 'white',
}) => {
  const [isReadMoreExpanded, expandReadMore] = useState(false);
  const [height, setHeight] = useState(0);
  // eslint-disable-next-line vcc-www/use-breakpoints

  const ref = useRef() as MutableRefObject<HTMLElement>;

  const markdownRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (ref?.current) {
      setHeight(ref.current.clientHeight);
    }
    if (markdownRef?.current) {
      setHeight(markdownRef.current.clientHeight);
    }
  }, [text]);

  const handleReadMoreClick = () => {
    expandReadMore(!isReadMoreExpanded);
  };

  const showExpendBtn = readMoreTitle && !isReadMoreExpanded && height > 48;

  const readMoreBackgroundClassName =
    background === 'dark' || background === 'light'
      ? 'bg-secondary'
      : 'bg-primary';

  return (
    <>
      {heading && (
        <>
          {popUpDisclaimer && popUpDisclaimer.description ? (
            <PopUpDisclaimer
              {...popUpDisclaimer}
              trackLabel={`open PopUpDisclaimer | ${heading}`}
            >
              {(icon) => (
                <Text
                  as={withHeadingType}
                  variant={headingVariant}
                  subStyle="emphasis"
                  extend={[extendHeadingCSS]}
                  data-sources={headingSource}
                >
                  {heading} {icon}
                </Text>
              )}
            </PopUpDisclaimer>
          ) : (
            <Text
              as={withHeadingType}
              variant={headingVariant}
              subStyle="emphasis"
              extend={[extendHeadingCSS]}
              data-sources={headingSource}
            >
              {heading}
            </Text>
          )}
        </>
      )}
      <Spacer size={spacerSize} />

      {supportMarkdown ? (
        <Flex extend={textWithReadMoreCSS}>
          <Flex
            ref={markdownRef}
            onClick={() => {
              expandReadMore(false);
            }}
          >
            <Markdown_DEPRECATED_VCCUI
              extend={
                showExpendBtn
                  ? [extendTextCss, multilineTextCSS(isReadMoreExpanded)]
                  : [extendTextCss]
              }
              markdown={text}
              variant={markdownVariant}
            />
          </Flex>
          {showExpendBtn && (
            <Track
              eventAction="link|click"
              eventLabel={
                readMoreTrackingLabel
                  ? readMoreTrackingLabel
                  : `read more | ${
                      index || index === 0 ? index + ' | ' : ''
                    }${heading}`
              }
            >
              <button
                type="button"
                className="absolute bottom-4 end-0"
                onClick={handleReadMoreClick}
                aria-label={ariaLabelReadMoreButton || ''}
                aria-expanded={isReadMoreExpanded}
                tabIndex={0}
              >
                <p className={`px-4 font-16 ${readMoreBackgroundClassName}`}>
                  ... {readMoreTitle}
                </p>
              </button>
            </Track>
          )}
        </Flex>
      ) : (
        <Flex extend={textWithReadMoreCSS}>
          <Text
            extend={
              showExpendBtn
                ? [
                    textCSS(enableLineBreak),
                    extendTextCss,
                    multilineTextCSS(isReadMoreExpanded),
                  ]
                : [textCSS(enableLineBreak), extendTextCss]
            }
            onClick={() => {
              expandReadMore(false);
            }}
            ref={ref}
            data-sources={textSource}
          >
            {text}
          </Text>
          {showExpendBtn && (
            <Track
              eventAction="link|click"
              eventLabel={
                readMoreTrackingLabel
                  ? readMoreTrackingLabel
                  : `read more | ${
                      index || index === 0 ? index + ' | ' : ''
                    }${heading}`
              }
            >
              <button
                type="button"
                className="absolute bottom-4 end-0"
                onClick={handleReadMoreClick}
                aria-label={ariaLabelReadMoreButton || ''}
                aria-expanded={isReadMoreExpanded}
                tabIndex={0}
              >
                <p className={`px-4 font-16 ${readMoreBackgroundClassName}`}>
                  ... {readMoreTitle}
                </p>
              </button>
            </Track>
          )}
        </Flex>
      )}
    </>
  );
};

export default HeadingAndText;

const textCSS: (enableLineBreak: boolean) => ExtendCSS = (enableLineBreak) => ({
  color: 'foreground.secondary',

  extend: {
    condition: enableLineBreak,
    style: {
      whiteSpace: 'pre-line',
    },
  },
});

const multilineTextCSS: (isExpanded: boolean) => ExtendCSS =
  (isExpanded: boolean) =>
  ({ theme: { baselineGrid, color } }) => {
    return {
      marginTop: 0.5 * baselineGrid,
      position: 'relative',
      maxHeight: isExpanded ? '100hv' : baselineGrid * 6.5,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      color: color.foreground.secondary,
      transition: 'max-height 1s ease',
    };
  };

const textWithReadMoreCSS: ExtendCSS = {
  position: 'relative',
};
