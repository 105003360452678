import { useEffect, useState } from 'react';
import throttle from 'lodash/throttle';

export const useWindowHeightAndScroll = () => {
  const [windowScroll, setWindowScroll] = useState(0);
  const [windowHeight, setWindowHeight] = useState(0);

  useEffect(() => {
    setWindowScroll(window.pageYOffset);
    setWindowHeight(window.innerHeight);

    const handleScroll = throttle(() => {
      setWindowScroll(window.pageYOffset);
    }, 100);

    const handleResize = () => {
      setWindowHeight(window.innerHeight);
    };

    window.addEventListener('scroll', handleScroll);

    if (typeof ResizeObserver === 'function') {
      const observer = new ResizeObserver(handleResize);
      observer.observe(document.body);
      return () => {
        window.removeEventListener('scroll', handleScroll);
        observer.disconnect();
      };
    }

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [setWindowScroll, setWindowHeight]);

  return { windowScroll, windowHeight };
};
